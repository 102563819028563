import NavbarItem from "../NavbarItem/NavbarItem";
import Link from "../../Link/Link";
import smallLogo from "../../../assets/img/logo/logo_2.png";

const Navbar = () => {

    return (
        <div className="flex justify-center">
            <div className="flex pt-4 w-[95%] xl:w-[98%] font-gildadisplay xl:text-lg xl:space-x-4">

                <img src={ smallLogo } alt="Logo du site Solène Pierre" className="w-12 h-auto hidden xl:block" />

                <div className="flex flex-col flex-grow justify-center">

                    <div className="flex justify-between border-b-2 border-black pb-2">
                        <Link label="Solène PIERRE" uri="/" />

                        <div className="flex space-x-4">

                            <NavbarItem label="Qui suis-je ?" sectionId="whoami" />
                            <NavbarItem label="Mes projets" sectionId="projects" />
                            <NavbarItem label="Me contacter" sectionId="contactme" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
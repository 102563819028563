import aboutimage from "../../assets/img/about/about.jpg";

const WhoAmI = () => {

    return (
        <div id="whoami" className="min-h-screen bg-floral-white flex justify-center">

            <div className="flex p-4 xl:p-0">

                <div className="hidden xl:flex w-2/5 bg-red-500 justify-center items-center">

                    <img src={ aboutimage } alt="temp" className="w-full h-full" />

                </div>

                <div className="flex flex-col justify-center xl:w-3/5 space-y-10 xl:space-y-24 px-4 xl:pl-8 xl:pr-20 font-gildadisplay">

                    <h2 className="text-5xl xl:text-6xl italic text-center xl:text-start">Qui suis-je ?</h2>

                    <div className="flex flex-col space-y-10">
                        <p>
                            Mon parcours est un voyage à travers les arts et les espaces, une exploration où chaque ligne, chaque couleur et chaque texture racontent une <span className="font-bold font-noto">histoire unique</span>.
                        </p>

                        <p>
                            Depuis mon plus jeune âge, j'ai toujours été fascinée par l'<span className="font-bold font-noto">harmonie</span> des formes, des couleurs et des textures. Cette fascination m'a naturellement conduite vers le domaine de l'architecture d'intérieur, où je peux exprimer ma créativité tout en répondant aux besoins spécifiques de chaque client.
                        </p>

                        <p>
                            Diplômée d'un Master en Architecture d'Intérieur et Design à Ynov Bordeaux, j'ai affiné mon savoir-faire et ma <span className="font-bold font-noto">sensibilité</span> artistique pour façonner des environnements qui touchent l'âme.
                            Mon parcours académique et professionnel m'a permis de développer une expertise solide en design d'intérieur, me permettant de concevoir des espaces à la fois esthétiques et fonctionnels.
                        </p>

                        <p>
                            Je crois fermement que chaque projet est une <span className="font-bold font-noto">aventure unique</span>.
                            Mon approche est centrée sur l'écoute et la <span className="font-bold font-noto">collaboration</span>, afin de comprendre pleinement vos attentes et vos aspirations.
                            Ensemble, nous explorerons les possibilités infinies pour transformer vos idées en réalité tangible.
                            Que ce soit pour la rénovation d'un appartement, l'aménagement d'un bureau ou la création d'un espace commercial, je m'engage à vous offrir des solutions sur mesure, adaptées à votre style de vie et à votre <span className="font-bold font-noto">personnalité</span>.
                            Ma philosophie repose sur l'innovation et l'attention aux <span className="font-bold font-noto">détails</span>. Chaque élément est soigneusement pensé pour créer une ambiance harmonieuse et équilibrée.
                            Je puise mon inspiration dans diverses sources, telles que l'art contemporain, la nature et l'architecture traditionnelle, pour concevoir des espaces qui racontent une <span className="font-bold font-noto">histoire</span> et suscitent des <span className="font-bold font-noto">émotions</span>.
                        </p>

                        <p>
                            Dynamique, rigoureuse et passionnée, je mets toute mon énergie au service de vos projets.
                            Mon objectif est de vous accompagner tout au long du processus de création, en veillant à ce que chaque étape soit une expérience enrichissante et satisfaisante.
                            Ensemble, nous donnerons vie à des espaces qui dépassent vos attentes et reflètent votre <span className="font-bold font-noto">vision unique</span>.
                        </p>
                    </div>

                    <div className="flex justify-center xl:justify-end">

                        <a className="border font-gildadisplay px-10 py-4 border-black hover:bg-gray-100 bg-white" href="files/cv/cv.pdf" download="cv_solene_pierre_2024.pdf">Télécharger mon CV</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhoAmI;
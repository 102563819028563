import {useNavigate} from "react-router-dom";

type NavbarItemProps = {
    label: string;
    sectionId?: string;
    uri?: string;
};

const NavbarItem = (props: NavbarItemProps) => {

    const navigate = useNavigate();

    const handleNavigation = () => {

        if (props.uri) {

            return handlePageNavigation(props.uri);
        }

        if (props.sectionId) {

            return handleSectionNavigation(props.sectionId);
        }
    }

    const handleSectionNavigation = (sectionId: string | undefined) => {

        if (!sectionId) {

            return;
        }

        document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
    };

    const handlePageNavigation = (uri: string | undefined) => {

        if (!uri) {

            return;
        }

        navigate(uri);
    };

    return (
        <ul className="flex items-center justify-center h-full list-none">
            <li className="text-center cursor-pointer">
                <button onClick={() => handleNavigation()} className="hover:underline">{ props.label }</button>
            </li>
        </ul>
    );
};

export default NavbarItem;
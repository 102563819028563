import Presentation from "../../components/Presentation/Presentation";
import Contact from "../../components/Contact/Contact";
import WhoAmI from "../../components/WhoAmI/WhoAmI";
import Projects from "../../components/Projects/Projects";
import React, {useEffect, useState} from "react";
import GoToTop from "../../components/GoToTop/GoToTop";
import {useAnimationContext} from "../../context/AnimationContext/AnimationContext";

const Main = () => {

    const [showSections, setShowSections] = useState(false);

    const animationContext = useAnimationContext();

    useEffect(() => {

        let time = 6000;

        if (animationContext.isVisited) {

            time = 0;
        }

        const timer = setTimeout(() => {
            setShowSections(true);
            animationContext.setVisited(true);
        }, time);

        return () => clearTimeout(timer);
    }, [animationContext]);

    return (
        <div className="relative">

            <div className="flex flex-col">

                <Presentation />

                {
                    showSections && (
                        <>
                            <WhoAmI />
                            <Projects />
                            <Contact />
                        </>
                    )
                }
            </div>

            <GoToTop />
        </div>
    );
};

export default Main;
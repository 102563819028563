import React, {createContext, ReactNode, useContext, useState} from "react";

type AnimationContextProps = {
    isVisited: boolean;
    setVisited: React.Dispatch<React.SetStateAction<boolean>>;
};

const AnimationContext = createContext<AnimationContextProps | undefined>(undefined);

export const AnimationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [isVisited, setVisited] = useState<boolean>(false);

    return (
        <AnimationContext.Provider value={{ isVisited, setVisited }}>
            { children }
        </AnimationContext.Provider>
    );
};

export const useAnimationContext = (): AnimationContextProps => {

    const context = useContext(AnimationContext);

    if (!context) {

        throw new Error('useAnimationContext must be used within a StyleProvider');
    }

    return context;
};
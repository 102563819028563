type FormInputProps = {
    label: string;
    value: string;
    onChange: Function;
    valid: boolean;
    disabled?: boolean;
};

const FormInput = (props: FormInputProps) => {

    const getStyle = () => {

        let baseStyle = "bg-transparent border-b-2 w-full focus:outline-none text-black font-gildadisplay placeholder:opacity-70";

        if (props.valid) {

            return `${baseStyle} placeholder-black border-black`
        }

        return `${baseStyle} placeholder-red-500 border-red-500`;
    }

    return (
        <input
            placeholder={ props.label }
            value={ props.value }
            onChange={ (e) => props.onChange(e.target.value) }
            className={ getStyle() }
            disabled={ props.disabled }
        />
    );
};

export default FormInput;
import ProjectTemplate from "../../../components/Projects/ProjectTemplate/ProjectTemplate";
import {Image} from "../../../model/Image/Image";
import office1 from "../../../assets/img/maison-ecocitoyenne/office_1.png";
import office2 from "../../../assets/img/maison-ecocitoyenne/office_2.png";
import office3 from "../../../assets/img/maison-ecocitoyenne/office_3.png";
import office4 from "../../../assets/img/maison-ecocitoyenne/office_4.png";
import office5 from "../../../assets/img/maison-ecocitoyenne/office_5.png";
import office6 from "../../../assets/img/maison-ecocitoyenne/office_6.png";
import generalreception from "../../../assets/img/maison-ecocitoyenne/general_reception.png";
import repaircoffeereception from "../../../assets/img/maison-ecocitoyenne/repair_coffe_table.png";
import reception from "../../../assets/img/maison-ecocitoyenne/reception.png";
import closedworkshop from "../../../assets/img/maison-ecocitoyenne/closed_workshop.png";
import openedworkshop from "../../../assets/img/maison-ecocitoyenne/opened_workshop.png";
import coffeegeneralview from "../../../assets/img/maison-ecocitoyenne/coffee_general_view.png";
import coffeecounter from "../../../assets/img/maison-ecocitoyenne/coffee_counter.png";
import coffeecounterexposition from "../../../assets/img/maison-ecocitoyenne/coffee_counter_and_exposition.png";
import coffeeexterior from "../../../assets/img/maison-ecocitoyenne/coffee_exterior.png";
import materiallibrary from "../../../assets/img/maison-ecocitoyenne/material_library.png";
import wallexposition from "../../../assets/img/maison-ecocitoyenne/wall_exposition.png";
import gateway from "../../../assets/img/maison-ecocitoyenne/gateway.png";
import repaircoffeetable from "../../../assets/img/maison-ecocitoyenne/repair_coffe_table.png";
import entrance from "../../../assets/img/maison-ecocitoyenne/entrance.png";
import terrace from "../../../assets/img/maison-ecocitoyenne/terrace.png";
import togrocerystore from "../../../assets/img/maison-ecocitoyenne/to_grocery_store.png";
import plan from "../../../assets/img/maison-ecocitoyenne/plan.png";

const MaisonEcoCitoyenne = () => {

    const images: Image[] = [
        {uri: office1, title: "Visuel des bureaux"},
        {uri: office2, title: "Visuel des bureaux"},
        {uri: office3, title: "Visuel des bureaux"},
        {uri: office4, title: "Visuel des bureaux"},
        {uri: office5, title: "Visuel des bureaux"},
        {uri: office6, title: "Visuel des bureaux"},
        {uri: generalreception, title: "Visuel de la réception générale"},
        {uri: repaircoffeereception, title: "Visuel de la réception du repair café"},
        {uri: reception, title: "Visuel de la réception"},
        {uri: closedworkshop, title: "Visuel de l'atelier fermé"},
        {uri: openedworkshop, title: "Visuel de l'atelier ouvert"},
        {uri: coffeegeneralview, title: "Visuel général du café"},
        {uri: coffeecounter, title: "Visuel du comptoir du café"},
        {uri: coffeecounterexposition, title: "Visuel du comptoir du café et de l'exposition"},
        {uri: coffeeexterior, title: "Visuel de l'extérieur du café"},
        {uri: materiallibrary, title: "Visuel de la matériauthèque"},
        {uri: wallexposition, title: "Visuel de l'exposition au mur"},
        {uri: gateway, title: "Visuel de la passerelle"},
        {uri: repaircoffeetable, title: "Visuel de la table du café"},
        {uri: entrance, title: "Visuel de l'entrée"},
        {uri: terrace, title: "Visuel de la terrasse"},
        {uri: togrocerystore, title: "Visuel du chemin vers l'épicerie"},
    ];

    const overviewParagraphs = [
        "Rubrique en cours de création..."
    ];

    const overviewImage = {uri: plan, title: "Plan de la maison écocitoyenne"};

    return (

        <ProjectTemplate
            background="bg-maisonecocitoyenne-presentation-background"
            title="La Maison Éco-Citoyenne"
            summary="Saint Pierre du Mont, 40280"
            galeryImages={ images }
            overviewImage={ overviewImage }
            overviewParagraphs={ overviewParagraphs }
        />
    );
};

export default MaisonEcoCitoyenne;
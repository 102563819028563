import ProjectNavbar from "../../Navbar/ProjectNavbar/ProjectNavbar";

type ProjectPresentationProps = {
    background: string;
    title: string;
    summary?: string;
};

const ProjectPresentation = (props: ProjectPresentationProps) => {

    const background = `${props.background} bg-cover bg-center`

    return (
        <div className="flex flex-col min-h-screen bg-floral-white space-y-2">

            <ProjectNavbar />

            <div className={ `flex flex-col flex-grow justify-center items-center ${background}` }>
                <div className="flex flex-col xl:items-end text-center xl:text-start">
                    <h1 className="text-5xl xl:text-7xl italic font-gildadisplay text-white">{ props.title }</h1>
                    { props.summary && <p className="font-gildadisplay italic text-white text-xl">{ props.summary }</p> }
                </div>
            </div>
        </div>
    );
};

export default ProjectPresentation;
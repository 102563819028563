import React from "react";
import LinkIcon from "../LinkIcon/LinkIcon";
import {PiLinkedinLogoThin} from "react-icons/pi";
import ContactForm from "./ContactForm/ContactForm";

const Contact = () => {

    return (
        <div id="contactme" className="min-h-screen bg-white flex justify-center">

            <div className="flex w-[98%] justify-center items-center">
                <div className="flex flex-col space-y-6 p-6 xl:w-1/3">

                    <ContactForm />

                    <div className="flex justify-center space-x-6">
                        <LinkIcon label="Solène PIERRE" uri="https://www.linkedin.com/in/sol%C3%A8ne-pierre-290371238/" icon={ <PiLinkedinLogoThin /> } />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
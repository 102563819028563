import GoToTop from "../../GoToTop/GoToTop";
import ProjectPresentation from "../ProjectPresentation/ProjectPresentation";
import ProjectOverview from "../ProjectOverview/ProjectOverview";
import {Image} from "../../../model/Image/Image";
import React from "react";
import ProjectGallery from "../ProjectGallery/ProjectGallery";
import ScreenSizeInfo from "../../ScreenSizeInfo/ScreenSizeInfo";

type ProjectTemplateProps = {
    background: string;
    title: string;
    summary?: string;
    galeryImages: Image[];
    overviewImage: Image;
    overviewParagraphs: string[];
};

const ProjectTemplate = (props: ProjectTemplateProps) => {

    return (
        <div className="flex flex-col relative">

            <GoToTop/>

            <ProjectPresentation background={ props.background } title={ props.title } summary={ props.summary }/>

            <ProjectOverview image={ props.overviewImage } paragraphs={ props.overviewParagraphs }/>

            <ProjectGallery images={ props.galeryImages } />
        </div>
    );
};

export default ProjectTemplate;
import {Image} from "../../../model/Image/Image";

type ProjectOverviewProps = {
    image: Image;
    paragraphs: string[];
};

const ProjectOverview = (props: ProjectOverviewProps) => {

    const getImageWidth = () => {

        if (props.image.width) {

            return props.image.width;
        }

        return "auto";
    }

    const getImageHeight = () => {

        if (props.image.height) {

            return props.image.height;
        }

        return "auto";
    }

    const getStyle = () => {

        return {
            width: getImageWidth(),
            height: getImageHeight(),
        };
    }

    return (
        <div className="flex flex-col xl:flex-row min-h-screen bg-white">

            <div className="flex flex-col xl:w-1/2 p-6 xl:p-10 justify-center">

                <p className="font-gildadisplay space-y-8 xl:text-xl">

                    {props.paragraphs.map((paragraph, index) => (
                        <p key={paragraph}>{paragraph}</p>
                    ))}
                </p>
            </div>

            <div className="flex flex-col xl:w-1/2 items-center justify-center p-6 xl:p-10">

                <img
                    src={props.image.uri}
                    alt={props.image.title}
                    style={getStyle()}
                />
            </div>
        </div>
    );
};

export default ProjectOverview;
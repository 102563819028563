import Link from "../Link/Link";
import logo from "../../assets/img/logo/logo_2.png";

const BuildingPage = () => {

    return (
        <div className="min-h-screen bg-floral-white flex flex-col items-center justify-center space-y-4">

            <img src={ logo } alt="Logo du site Solène Pierre" className="w-24 h-auto" />
            <h1 className="font-gildadisplay text-4xl text-center p-2 xl:p-0 xl:text-start">Cette page est en cours de construction...</h1>
            <Link label="Revenir sur la page principale" uri="/" />
        </div>
    );
}

export default BuildingPage;
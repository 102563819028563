import {Image} from "../../../model/Image/Image";
import React from "react";
import ProjectGalleryImage from "./ProjectGalleryImage/ProjectGalleryImage";

type ProjectGalleryProps = {
    images: Image[];
};

const ProjectGallery = (props: ProjectGalleryProps) => {

    return (

        <div className="min-h-screen bg-linen p-6">
            <div className="p-5 sm:p-8">
                <div className="columns-1 gap-5 sm:columns-2 sm:gap-8 md:columns-3 lg:columns-4 [&>img:not(:first-child)]:mt-8">
                    {props.images.map((image, index) => (
                        <ProjectGalleryImage key={ image.uri } id={ index } image={ image } />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectGallery;
import {ReactNode} from "react";
import { IconContext } from "react-icons";

type LinkIconProps = {
    label: string;
    uri: string;
    icon: ReactNode;
};

const LinkIcon = (props: LinkIconProps) => {

    return (
        <div className="flex items-center space-x-2 font-gildadisplay text-lg">
            <IconContext.Provider value={{ size: "3rem" }}>
                { props.icon }
            </IconContext.Provider>
            <a href={ props.uri } target="_blank" rel="noreferrer" className="hover:underline">{ props.label }</a>
        </div>
    );
};

export default LinkIcon;
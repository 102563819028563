import Navbar from "../Navbar/Navbar/Navbar";
import biglogo from "../../assets/img/logo/logo_1.png";
import {useAnimationContext} from "../../context/AnimationContext/AnimationContext";

const Presentation = () => {

    const animationContext = useAnimationContext();

    const getNavbarStyle = () => {

        if (animationContext.isVisited) {

            return "";
        }

        return "fadeIn animate-fadeIn delay-4500";
    };

    const getLogoStyle = () => {

        const baseStyle = "w-2/3 h-auto";

        if (animationContext.isVisited) {

            return baseStyle;
        }

        return `${baseStyle} opacity-0 animate-shrink delay-0`;
    };

    const getTitleStyle = () => {

        const baseStyle = "flex flex-col items-center font-gildadisplay space-y-2 pb-10 text-[#5A5A59]";

        if (animationContext.isVisited) {

            return baseStyle;
        }

        return `${baseStyle} fadeIn animate-fadeIn delay-2500`;
    };

    const getQuoteStyle = () => {

        const baseStyle = "italic font-gildadisplay text-center px-6 md:px-0 md:text-start md:text-xl pb-12 text-[#5A5A59]";

        if (animationContext.isVisited) {

            return baseStyle;
        }

        return `${baseStyle} fadeIn animate-fadeIn delay-3500`;
    };

    return (
        <div className="min-h-screen bg-floral-white flex flex-col">

            <div className={ getNavbarStyle() }>

                <Navbar />
            </div>

            <div className="flex flex-grow flex-col items-center justify-center space-y-4 xl:space-y-0">

                <img src={ biglogo } alt="Logo Solène Pierre" className={ getLogoStyle() } />

                <div className={ getTitleStyle() }>
                    <h1 className="text-3xl md:text-5xl tracking-[.25em]">SOLÈNE PIERRE</h1>
                    <h1 className="md:text-4xl">Architecte d'Intérieur & Designer</h1>
                </div>

                <h2 className={ getQuoteStyle() }>
                    "Les plus beaux espaces sont ceux qui reflètent l'âme et la vision de ceux qui les habitent"
                </h2>
            </div>
        </div>
    );
};

export default Presentation;
import React, {useEffect, useState} from "react";
import {MdKeyboardArrowUp} from "react-icons/md";

const GoToTop = () => {

    const [isGoTopVisible, setGoTopVisible] = useState(false);

    useEffect(() => {

        const handleGoTopVisibility = () => {

            window.scrollY > (window.innerHeight/2) ? setGoTopVisible(true) : setGoTopVisible(false);
        };

        window.addEventListener('scroll', handleGoTopVisibility);

        return () => {

            window.removeEventListener('scroll', handleGoTopVisibility)
        }
    }, []);

    const handleScrollToTop = () => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (!isGoTopVisible) {

        return null;
    }

    return (
        <div className="fixed bottom-6 right-6 z-10 border border-black rounded-full opacity-25 hover:opacity-100 hover:cursor-pointer text-4xl" onClick={ handleScrollToTop }>
            <MdKeyboardArrowUp />
        </div>
    );
};

export default GoToTop;
import {useNavigate} from "react-router-dom";

type LinkProps = {
    label: string;
    uri: string;
};

const Link = (props: LinkProps) => {

    const navigate = useNavigate();

    const handleNavigation = (uri : string) => {

        navigate(uri);
    };

    return (
        <button onClick={() => handleNavigation(props.uri)} className="hover:underline font-ptserif">{ props.label }</button>
    );
};

export default Link;